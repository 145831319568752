<template>
  <div class="fission_carousel_container">
    <div class="fissionprofit_case_carousel">
      <el-carousel
        indicator-position="outside"
        height="1049px"
        :interval="400000"
      >
        <el-carousel-item v-for="item in 1" :key="item">
          <div class="carousel-item">
            <div class="fissionprofit_case_left">
              <div class="wholehouse_case">
                制定全国裂变计划，从产品设计到渠道建设，从项目分拆到营销标准化制定，形成闭环，1年度不到的时间，招商40多家城市代理，将近70城市合伙人，收款人民币1300万元
              </div>
              <div class="wholehouse_photo">
                <img src="@/assets/images/fission/ygb.png" alt="" />
              </div>
            </div>
            <div class="fissionprofit_case_right">
              <div class="beauty_industry_yang">
                <div class="photo">
                  <img src="@/assets/images/fission/yz.png" alt="" />
                </div>
                <div class="case">
                  <div class="fission_carousel_case_top_container">
                    <div class="case_tag">
                      <div
                        class="Tag"
                        :style="{
                          width: '144px',
                          height: '44px'
                        }"
                      >
                        美容行业杨总
                      </div>
                    </div>
                    <div class="case_harvest">
                      消费型股东21位，收款近200万，新店实现没开店就融资70万并拥有70%的股份
                    </div>
                    <div class="case_tips">
                      按照泉邦定制的方案，将门店优质顾客、身边有资源的人脉和资金雄厚的合作伙伴都通过股东方案吸引进来，共同打造一支能够源源不断带来新业务的实力团队。
                    </div>
                    <div class="left"></div>
                  </div>
                </div>
              </div>
              <div class="beauty_industry_wang">
                <div class="case">
                  <div class="fission_carousel_case_bottom_container">
                    <div class="case_tag">
                      <div
                        class="Tag"
                        :style="{
                          width: '144px',
                          height: '44px'
                        }"
                      >
                        美容行业王总
                      </div>
                    </div>
                    <div class="case_harvest">
                      首批招募四天收款500万，第二批股东招募单店一天收款157万，新店融资200万，39天收款2000万
                    </div>
                    <div class="case_tips">
                      参加完泉邦的课程之后，心中有了大爱，觉得带领更多人创造财富是一件很有意义的事。跟对人，愉快的赚钱真的不难，感恩泉邦集团，期待未来共创辉煌。
                    </div>
                    <div class="right"></div>
                  </div>
                </div>
                <div class="photo">
                  <img src="@/assets/images/fission/wq.png" alt="" />
                </div>
              </div>
              <div class="result">
                <div class="logo">
                  <img src="@/assets/images/fission/logo2.png" alt="" />
                </div>
                <div class="content">
                  竞争的本质是模式的较量<br />
                  我们给您带来全新的商业思维，<br />
                  为您找到适宜企业的盈利模式！<br />
                  带您突破重围，一鸣惊人
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FissionCarousel',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.fissionprofit_case_carousel {
  display: flex;
  justify-content: center;
  height: 1169px;

  .el-carousel {
    width: 1558px;
  }

  .carousel-item {
    display: flex;
    width: 1558px;
    height: 1169px;
  }

  .fissionprofit_case_left {
    width: 779px;
    background-color: #f7f9fd;
    &:hover {
      color: #ffffff;
      background: #1651dd;
    }

    .wholehouse_case {
      height: 264px;
      padding: 23px 43px 0px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 56px;
    }

    .wholehouse_photo {
      height: 785px;
    }
  }

  .fissionprofit_case_right {
    width: 779px;
    div {
      display: flex;
    }

    .beauty_industry_yang {
      background-color: #f7f9fd;
      display: flex;
      .photo {
        width: 50%;
      }
      .case {
        width: 50%;
      }
      &:hover {
        color: #ffffff;
        background: #1651dd;
      }
      &:hover .left::after {
        color: #ffffff;
        border-color: transparent transparent transparent #1651dd;
      }
      &:hover .Tag {
        border: 1px solid #ffffff;
      }
    }
    .beauty_industry_wang {
      background-color: #f7f9fd;
      .photo {
        width: 50%;
      }
      .case {
        width: 50%;
      }
      &:hover {
        color: #ffffff;
        background: #1651dd;
      }
      &:hover .right::before {
        color: #ffffff;
        border-color: transparent transparent transparent #1651dd;
      }
      &:hover .Tag {
        border: 1px solid #ffffff;
      }
    }
    .result {
      height: 350px;
      background: #010343;
      .logo {
        width: 136px;
        height: 140px;
        margin-top: 43px;
        margin-left: 43px;
      }
      .content {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 56px;
        margin-top: 85px;
        margin-left: 171px;
      }
    }
  }

  .fission_carousel_case_top_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 27px 31px 0px 56px;
  }

  .fission_carousel_case_bottom_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 27px 31px 0px 56px;
  }

  .case_tag {
    font-size: 16px;
    font-weight: 400;
  }

  .case_harvest {
    margin-top: 20px;
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
  }

  .case_tips {
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 25px;
  }

  .left {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 111px;
      left: -100px;
      width: 0px;
      height: 0px;
      border-width: 50px;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
      transform: rotate(180deg);
    }
  }
  .right {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 111px;
      right: -100px;
      width: 0px;
      height: 0px;
      border-width: 50px;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
    }
  }
  .Tag {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #333333;
    border-radius: 100px;
  }
  ::v-deep .el-carousel__indicators {
    margin-top: 55px;
    display: none;
  }

  ::v-deep .el-carousel__button {
    width: 32px;
    height: 5px;
    background: #e0dfdf;
    border-radius: 3px;
  }

  ::v-deep .is-active button {
    background-color: #0145e6;
  }
}
</style>
