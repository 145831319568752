<template>
  <div class="fission_course_container">
    <div class="fission_course_content">
      <div class="course_content_left">
        <div class="title">
          课程内容
        </div>
        <div class="introduce">
          《裂变式盈利》就是让企业在原有产品和项目的基础上，结合当下的市场环境，嫁接一套新的商业模式和系统，转变经营思维，改变经营方式。
          <br />
          <div class="introduce_item">
            以专业的角度，分析不同企业所遇到的问题，以助力企业更好地发展。
            通过裂变思维创造更多的盈利，最终实现企业的人才裂变、渠道裂变、时间裂变。
          </div>
        </div>
        <div class="process">
          <ul>
            <li>
              <div class="process_number">ONE</div>
              <div class="process_question">如何捆绑人才，把员工变成股东</div>
            </li>
            <li>
              <div class="process_number">TWO</div>
              <div class="process_question">如何捆绑客户，让客户疯狂转介绍</div>
            </li>
            <li>
              <div class="process_number">THREE</div>
              <div class="process_question">如何拓客、留客、锁客、消费升级</div>
            </li>
            <li>
              <div class="process_number">FOUR</div>
              <div class="process_question">
                如何不花自己钱，让自己的企业裂变全国
              </div>
            </li>
            <li>
              <div class="process_number">FIVE</div>
              <div class="process_question">
                如何让消费者不用花钱、代理商不用卖货、投资人只赚不赔
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="course_content_right">
        <div class="question">为什么要学裂变式盈利？什么是正确的盈利模式？</div>
        <div class="answer">
          答案并不复杂，无非就是看商业模式是否成立，能否给企业带来持续不断的收入即可。纵观脱颖而出的企业与泯然于众的企业之间的区别，我们不难看出，大部分企业其实并不知道什么样的盈利模式才真正适合自己。
        </div>
        <div class="couese_ace_lecturer">
          <div class="couese_ace_lecturer_title">
            课程金牌讲师
          </div>
          <div class="couese_ace_lecturer_list">
            <ul>
              <li>
                <div class="lecturer_avatar">
                  <img src="@/assets/images/fission/yyc.png" alt="" />
                </div>
                <div class="lecturer_name">杨雨辰</div>
                <div class="lecturer_identity">
                  <div>泉邦集团联合创始人</div>
                  <div>会员模式主讲老师</div>
                  <div>盈利模式设计专家</div>
                </div>
              </li>
              <li>
                <div class="lecturer_avatar">
                  <img src="@/assets/images/fission/mbc.png" alt="" />
                </div>
                <div class="lecturer_name">马煜超</div>
                <div class="lecturer_identity">
                  <div>泉邦集团董事长</div>
                </div>
              </li>
              <li>
                <div class="lecturer_avatar">
                  <img src="@/assets/images/fission/mrw.png" alt="" />
                </div>
                <div class="lecturer_name">W先生</div>
                <div class="lecturer_identity">
                  <div>泉邦集团天使投资人</div>
                  <div class="wrap">
                    中富信合投资管理<span>公司合伙人</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.fission_course_content {
  height: 1013px;
  width: 100%;
  padding: 42px 181px 0px;
  display: flex;
  justify-content: center;
}

.course_content_left {
  width: 757px;
  height: 939px;
  padding: 43px 32px 32px;

  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    margin-bottom: 21px;
  }

  .introduce {
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 48px;
    .introduce_item {
      text-indent: 2em;
    }
  }

  .process {
    li {
      display: flex;
      justify-content: space-between;
      margin-top: 21px;
    }
    .process_number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 77px;
      background: #1651dd;
      font-size: 19px;
      font-weight: 500;
      color: #ffffff;
    }
    .process_question {
      width: 607px;
      font-size: 24px;
      font-weight: 500;
      background: #f7f9fd;
      color: #010343;
      padding: 20px 87px;
    }
  }
}

.course_content_right {
  width: 757px;
  height: 939px;
  padding: 43px 23px 0px;
  margin-left: 43px;

  .question {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
  }

  .answer {
    width: 695px;
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 48px;
    margin-top: 21px;
  }
}

.couese_ace_lecturer {
  .couese_ace_lecturer_title {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
    margin-top: 43px;
    margin-bottom: 23px;
  }
  .couese_ace_lecturer_list {
    ul {
      display: flex;
    }
    li {
      margin-right: 11px;
      width: 224px;
    }
    .lecturer_avatar {
      height: 231px;
    }
    .lecturer_name {
      height: 45px;
      background: #1651dd;
      text-align: center;
      line-height: 45px;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
    }
    .lecturer_identity {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 224px;
      height: 205px;
      background: #f7f9fd;
      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010343;
      .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
