<template>
  <div class="fission_container">
    <!-- 顶部导航的占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 裂变式盈利标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 裂变式盈利封面图 -->
    <QbCover :src="src"></QbCover>

    <!-- 裂变式盈利会务课程安排 -->
    <QbSchedule :schedule="schedule"></QbSchedule>

    <!-- 裂变式盈利课程内容 -->
    <FissionContent></FissionContent>

    <!-- 裂变式盈利案例轮播图 -->
    <FissionCarousel></FissionCarousel>

    <!-- 报名组件 -->
    <QbApplication></QbApplication>
  </div>
</template>

<script>
import FissionCarousel from './components/fission_carousel.vue'
import FissionContent from './components/fission_content.vue'
export default {
  name: 'Fission',
  components: {
    FissionCarousel,
    FissionContent
  },
  data() {
    return {
      title: '《裂变式盈利》',
      complement: 'Compulsory courses for entrepreneurs',
      src: require('@/assets/images/fission/fission_cover.png'),
      schedule: require('@/assets/images/fission/fission_schedule.png')
    }
  }
}
</script>

<style lang="less" scoped></style>
